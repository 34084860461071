

html {
  overflow: hidden;
  position: relative;
  background: url("./assets/background.png") no-repeat center center fixed;
  background-size: cover;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: Chakra Petch, sans-serif;
  color: #64fff7;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1000px) and (max-height: 1000px) and (orientation: landscape) {
  body {
    height: 100vh !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
